import { gql } from '@apollo/client/core';

export const CameraDeviceGatewayFieldsFragment = gql`
  fragment CameraDeviceGatewayFieldsFragment on qx_VideoSource {
    deviceID
    gatewayID
    deviceConnectionStatus
    gatewaySyncStatus
    gatewayConnectionStatus
    gatewayPlaceID
  }
`;

export const CameraSearchFragment = gql`
  fragment CameraSearchFragment on qx_VideoSource {
    id
    enabled
    name
    lastMotionAt
    profileMSupported
    place {
      id
      name
      hierarchy {
        name
      }
      toHomePlaces {
        customerPlaceID
        integratorPlaceID
      }
    }
    scheduleID
    ...CameraDeviceGatewayFieldsFragment
    device {
      id
      make
      model
      serial
      firmware
      deviceAddress
      deviceMac
      port
      tls
      connectionStatus
      gateway {
        id
        name
        connectionStatus
        syncStatus
      }
    }
    mediaProfiles {
      id
      streamEnabled
      streamStatus
    }
    cloudStorageDays
    cloudStorageTimelapseDays
    timelapseInterval
  }
  ${CameraDeviceGatewayFieldsFragment}
`;

const CameraFieldsFragment = gql`
  fragment CameraFieldsFragment on qx_VideoSource {
    id
    name
    enabled
    cloudStorageDays
    cloudStorageTimelapseDays
    snapshotMediaProfileID
    timelapseInterval
    lastMotionAt
    motionActive
    mountPosition
    profileMSupported
    schedule {
      id
      blocks {
        id
        date
        duration
        weekdays
      }
    }
    motionActive
    mountPosition
    schedule {
      id
      blocks {
        id
        date
        duration
        weekdays
      }
    }
    place {
      id
      name
      hierarchy {
        name
      }
      toHomePlaces {
        customerPlaceID
        integratorPlaceID
      }
    }
    ...CameraDeviceGatewayFieldsFragment
    deviceID
    device {
      id
      name
      profileMSupported
      oldestMedia {
        type
        date
      }
      gatewayID
      gateway {
        id
        name
        name
        connectionStatus
        syncStatus
        place {
          id
          name
          timezone
          timezone
          type
          path
        }
      }
      deviceAnalyticSources {
        id
        analyticSource {
          id
          comment
          value
        }
      }
      port
      tls
      make
      model
      deviceAddress
      deviceMac
      deviceUsername
      devicePassword
      serial
      firmware
      connectionStatus
      createdAt
    }
    mediaProfiles(order_by: [{ refID: asc }]) {
      id
      refID
      videoSourceID
      snapshotUrl
      videoEncoderSettings
      streamEnabled
      streamStatus
      streamFile
    }
    fps
    imageSize
    imagingSettings
    imagingOptions
  }
  ${CameraDeviceGatewayFieldsFragment}
`;

// same as `SingleCameraFragment`, but without snapshots
const SingleCameraSubscriptionFragment = gql`
  fragment SingleCameraSubscriptionFragment on qx_VideoSource {
    ...CameraFieldsFragment
  }
  ${CameraFieldsFragment}
`;

const SnapshotsFieldFragment = gql`
  fragment SnapshotsFieldFragment on qx_VideoSource {
    snapshots(limit: 1, order_by: { time: desc }, where: { cropped: { _eq: false } }) {
      id
      base64
      signedUrl
      aspectRatio
    }
  }
`;

const SingleCameraFragment = gql`
  fragment SingleCameraFragment on qx_VideoSource {
    ...CameraFieldsFragment
    ...SnapshotsFieldFragment
  }
  ${CameraFieldsFragment}
  ${SnapshotsFieldFragment}
`;

export const MediaProfileSearchFragment = gql`
  fragment MediaProfileSearchFragment on qx_MediaProfile {
    id
    refID
    streamEnabled
    streamStatus
    warped
    videoEncoderSettings
    rtspUrl
    place {
      id
      name
      hierarchy {
        name
      }
    }
  }
`;

export const MediaProfileFormFragment = gql`
  fragment MediaProfileFormFragment on qx_MediaProfile {
    id
    deviceID
    refID
    streamEnabled
    warped
    videoEncoderOptions
    videoEncoderSettings
  }
`;

export const GQL_CREATE_CAMERA_DEVICE = gql`
  mutation GQL_CREATE_CAMERA_DEVICE($device: CreateDeviceInput!) {
    createDevices(objects: [$device]) {
      id
      videoSources {
        ...SingleCameraFragment
      }
    }
  }
  ${SingleCameraFragment}
`;

export const GQL_GET_CAMERA_BY_ID = gql`
  query GQL_GET_CAMERA_BY_ID($id: bigint!) {
    videoSource(id: $id) {
      ...SingleCameraFragment
    }
  }
  ${SingleCameraFragment}
`;

export const GQL_SUB_CAMERA_BY_ID = gql`
  subscription GQL_SUB_CAMERA_BY_ID($id: bigint!) {
    videoSource(id: $id) {
      ...SingleCameraSubscriptionFragment
    }
  }
  ${SingleCameraSubscriptionFragment}
`;

export const GQL_MEDIA_PROFILE_SEARCH = gql`
  query GQL_MEDIA_PROFILE_SEARCH(
    $where: qx_MediaProfile_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_MediaProfile_order_by!]
  ) {
    mediaProfiles(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...MediaProfileSearchFragment
    }
    mediaProfileAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${MediaProfileSearchFragment}
`;

export const GQL_MEDIA_PROFILE_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_MEDIA_PROFILE_SEARCH_SUBSCRIPTION(
    $where: qx_MediaProfile_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_MediaProfile_order_by!]
  ) {
    mediaProfiles(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...MediaProfileSearchFragment
    }
  }
  ${MediaProfileSearchFragment}
`;

export const GQL_GET_CAMERA_MEDIA_PROFILE_FORM = gql`
  query GQL_GET_CAMERA_MEDIA_PROFILE_FORM($id: bigint!) {
    mediaProfile(id: $id) {
      ...MediaProfileFormFragment
    }
  }
  ${MediaProfileFormFragment}
`;

export const GQL_UPDATE_VIDEO_SOURCE_BY_ID = gql`
  mutation GQL_UPDATE_VIDEO_SOURCE_BY_ID($id: bigint!, $data: qx_VideoSource_set_input!) {
    updateVideoSource(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const GQL_DELETE_CAMERA_DEVICE_BY_ID = gql`
  mutation GQL_DELETE_CAMERA_DEVICE_BY_ID($id: String!) {
    deleteDeviceVideoData(deviceID: $id) {
      status
    }
  }
`;

export const GQL_GET_CAMERAS_BY_DEVICE_ID = gql`
  query GQL_GET_CAMERAS_BY_DEVICE_ID($id: String!) {
    videoSources(where: { device: { id: { _eq: $id } } }) {
      ...SingleCameraFragment
    }
  }
  ${SingleCameraFragment}
`;

export const GQL_CAMERA_SEARCH = gql`
  query GQL_CAMERA_SEARCH(
    $where: qx_VideoSource_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_VideoSource_order_by!]
  ) {
    videoSources(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...CameraSearchFragment
      snapshots(limit: 1, order_by: { time: desc }, where: { cropped: { _eq: false } }) {
        id
        base64
        signedUrl
        aspectRatio
      }
    }
    videoSourceAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${CameraSearchFragment}
`;

export const GQL_CAMERA_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_CAMERA_SEARCH_SUBSCRIPTION(
    $where: qx_VideoSource_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [qx_VideoSource_order_by!]
  ) {
    videoSources(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...CameraSearchFragment
    }
  }
  ${CameraSearchFragment}
`;

export const DeviceVideoSourceFormFragment = gql`
  fragment DeviceVideoSourceFormFragment on qx_VideoSource {
    id
    refID
    name
    enabled
    cloudStorageDays
    cloudStorageTimelapseDays
    timelapseInterval
    mountPosition
    placeID
    place {
      id
      name
    }
    mediaProfiles(order_by: [{ refID: asc }]) {
      id
      refID
      deviceID
      warped
      streamEnabled
      streamStatus
      videoEncoderOptions
      videoEncoderSettings
    }
  }
`;

export const GQL_SUB_CAMERA_DEVICE_FORM_CREATE = gql`
  subscription GQL_SUB_CAMERA_DEVICE_FORM_CREATE($id: String!) {
    videoSources(where: { deviceID: { _eq: $id } }) {
      ...DeviceVideoSourceFormFragment
    }
  }
  ${DeviceVideoSourceFormFragment}
`;
