import { gql } from '@apollo/client/core';

export const PeopleFragment = gql`
  fragment PeopleFragment on qx_Person {
    id
    externalPersonID
    createdAt
    updatedAt
    roleID
    role {
      id
      name
    }
    email
    employeeID
    title
    suffix
    firstName
    lastName
    toGroups {
      group {
        id
        name
      }
    }
    place {
      id
      name
      type
      addresses
    }
    salutation
    avatar_md
    department
    credentials {
      id
      pin
      value
      validFrom
      validTo
      enabled
    }
  }
`;

export const SinglePersonFragment = gql`
  fragment SinglePersonFragment on qx_Person {
    id
    externalPersonID
    createdAt
    updatedAt
    roleID
    role {
      id
      name
    }
    email
    employeeID
    title
    suffix
    firstName
    lastName
    lockdownImmunity
    placeID
    place {
      id
      name
      type
      addresses
      hierarchy {
        name
      }
    }
    salutation
    department
    phoneNumbers
    licensePlate
    certification
    credentials(order_by: { validFrom: asc, validTo: asc }) {
      id
      pin
      value
      validFrom
      validTo
      enabled
      status
    }
  }
`;

export const GQL_GET_PERSON_BY_ID = gql`
  query GQL_GET_PERSON_BY_ID($id: String!) {
    person(id: $id) {
      ...SinglePersonFragment
      avatar
    }
  }
  ${SinglePersonFragment}
`;

export const GQL_SUB_PERSON_BY_ID = gql`
  subscription GQL_SUB_PERSON_BY_ID($id: String!) {
    person(id: $id) {
      ...SinglePersonFragment
    }
  }
  ${SinglePersonFragment}
`;

export const PersonSearchFragment = gql`
  fragment PersonSearchFragment on qx_Person {
    id
    externalPersonID
    firstName
    lastName
    email
    employeeID
    department
    title
    lockdownImmunity
    createdAt
    updatedAt
    status # credentials status computed column
    credentials {
      id
      validFrom
      validTo
      enabled
    }
    roleID
    role {
      id
      name
    }
    place {
      id
      name
      hierarchy {
        name
      }
      path
    }
  }
`;

export const GQL_PERSON_SEARCH = gql`
  query GQL_PERSON_SEARCH($where: qx_Person_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Person_order_by!]) {
    people(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...PersonSearchFragment
      avatar_md
    }
    personAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${PersonSearchFragment}
`;

const PersonSearchMiniFragment = gql`
  fragment PersonSearchMiniFragment on qx_Person {
    id
    firstName
    lastName
    email
    avatar_sm
    place {
      id
      name
      hierarchy {
        name
      }
    }
  }
`;

export const GQL_PERSON_SEARCH_MINI = gql`
  query GQL_PERSON_SEARCH_MINI($where: qx_Person_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Person_order_by!]) {
    people(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...PersonSearchMiniFragment
    }
    personAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${PersonSearchMiniFragment}
`;

export const GQL_PERSON_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_PERSON_SEARCH_SUBSCRIPTION(
    $where: qx_Person_bool_exp,
    $limit: Int,
    $offset: Int,
    $orderBy: [qx_Person_order_by!],
    $currentScopePath: String
  ) {
    people(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...PersonSearchFragment
    }
  }
  ${PersonSearchFragment}
`;
