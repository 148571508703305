import { gql } from '@apollo/client/core';

export const SingleGroupFragment = gql`
  fragment SingleGroupFragment on qx_Group {
    id
    name
    externalGroupID
    place {
      name
      id
      path
      hierarchy {
        name
      }
    }
    toPeople_aggregate(where: { person: { place: { path: { _like: $currentScopePath } } } }) {
      aggregate {
        count
      }
    }
    permissions_aggregate(where: { place: { path: { _like: $currentScopePath } } }) {
      aggregate {
        count
      }
    }
    toPeople(limit: 5) {
      person {
        id
        avatar_sm
        firstName
        lastName
      }
    }
    thumbnail_lg
  }
`;

export const CRUDGroupFragment = gql`
  fragment CRUDGroupFragment on qx_Group {
    id
    name
  }
`;

export const GQL_GET_GROUP_BY_ID = gql`
  query GQL_GET_GROUP_BY_ID($id: String!, $currentScopePath: String!) {
    group(id: $id) {
      ...SingleGroupFragment
    }
  }
  ${SingleGroupFragment}
`;

export const GQL_SUB_GROUP_BY_ID = gql`
  subscription GQL_SUB_GROUP_BY_ID($id: String!, $currentScopePath: String!) {
    group(id: $id) {
      ...SingleGroupFragment
    }
  }
  ${SingleGroupFragment}
`;

export const GQL_CREATE_GROUP = gql`
  mutation GQL_CREATE_GROUP($group: qx_Group_insert_input!) {
    createGroup(object: $group) {
      ...CRUDGroupFragment
    }
  }
  ${CRUDGroupFragment}
`;

export const GQL_GROUP_ADD_PEOPLE = gql`
  mutation GQL_GROUP_ADD_PEOPLE($objects: [qx__PersonToGroup_insert_input!]!) {
    createPersonGroups(objects: $objects) {
      returning {
        personID
        groupID
      }
    }
  }
`;

export const GQL_UPDATE_GROUP_BY_ID = gql`
  mutation GQL_UPDATE_GROUP_BY_ID($id: String!, $group: qx_Group_set_input!) {
    updateGroup(_set: $group, pk_columns: { id: $id }) {
      ...CRUDGroupFragment
    }
  }
  ${CRUDGroupFragment}
`;

export const GQL_GROUP_REMOVE_PEOPLE = gql`
  mutation GQL_GROUP_REMOVE_PEOPLE($groupID: String!, $removePeople: [String!]!) {
    deletePersonGroups(where: { _and: [{ groupID: { _eq: $groupID } }, { personID: { _in: $removePeople } }] }) {
      returning {
        personID
      }
    }
  }
`;

export const GQL_DELETE_GROUP_BY_ID = gql`
  mutation GQL_DELETE_GROUP_BY_ID($id: String!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;

export const GroupSearchFragment = gql`
  fragment GroupSearchFragment on qx_Group {
    id
    name
    description
    thumbnail
    externalGroupID
    place {
      id
      name
      hierarchy {
        name
      }
    }
    toPeople_aggregate(where: { person: { place: { path: { _like: $currentScopePath } } } }) {
      aggregate {
        count
      }
    }
    permissions_aggregate(where: { place: { path: { _like: $currentScopePath } } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GQL_GROUP_SEARCH = gql`
  query GQL_GROUP_SEARCH($where: qx_Group_bool_exp, $limit: Int, $offset: Int, $orderBy: [qx_Group_order_by!], $currentScopePath: String!) {
    groups(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...GroupSearchFragment
    }
    groupAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${GroupSearchFragment}
`;

export const GQL_GROUP_SEARCH_SUBSCRIPTION = gql`
  subscription GQL_GROUP_SEARCH_SUBSCRIPTION(
    $where: qx_Group_bool_exp,
    $limit: Int,
    $offset: Int,
    $orderBy: [qx_Group_order_by!],
    $currentScopePath: String!
  ) {
    groups(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      ...GroupSearchFragment
    }
  }
  ${GroupSearchFragment}
`;
