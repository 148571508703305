import { gql } from '@apollo/client/core';

export const GQL_DIO_ACTIVATE_OUTPUT = gql`
  mutation GQL_DIO_ACTIVATE_OUTPUT($outputPointID: String!) {
    activateOutput(outputPointID: $outputPointID) {
      success
    }
  }
`;

export const GQL_DIO_DEACTIVATE_OUTPUT = gql`
  mutation GQL_DIO_DEACTIVATE_OUTPUT($outputPointID: String!) {
    deactivateOutput(outputPointID: $outputPointID) {
      success
    }
  }
`;
