import { gql } from '@apollo/client/core';

export const UseEventSearchFragment = gql`
  fragment UseEventSearchFragment on vEvents {
    id
    active
    card
    bits
    status
    time
    serverTime
    type
    duration
    hasVideo
    inCloud
    onGateway
    eventStart
    eventEnd
    personID
    person {
      id
      avatar_sm
      firstName
      lastName
    }
    doorID
    door {
      id
      name
    }
    videoSourceID
    videoSource {
      id
      name
    }
    mediaProfileID
    deviceID
    device {
      id
      name
    }
    placeID
    place {
      id
      name
      path
      hierarchy {
        name
      }
      gateway {
        id
        name
      }
    }
    profile
    snapshot {
      id
      signedUrl
      aspectRatio
    }
  }
`

export const GQL_SEARCH_V_EVENTS = gql`
  query GQL_SEARCH_V_EVENTS(
    $where: vEvents_bool_exp!,
    $limit: Int!,
    $order_by: [vEvents_order_by!]!
  ) {
    vEvents(
      where: $where,
      limit: $limit,
      order_by: $order_by,
    ) {
      ...UseEventSearchFragment
    }
  }
  ${UseEventSearchFragment}
`;

export const doorsPlacesAndCameras = gql`
  query doorsPlacesAndCameras($path: String!) {
    places(where: { path: { _like: $path } }) {
      id
      name
    }
    people(where: { place: { path: { _like: $path } } }) {
      id
      lastName
      firstName
    }
    doors(where: { place: { path: { _like: $path } } }) {
      id
      name
    }
    videoSources(where: { place: { path: { _like: $path } } }) {
      id
      name
    }
  }
`;
